import React from "react";
import {Button, Flex} from '@chakra-ui/react';
var ShowConnectButton = 'flex';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
if(urlParams.get('param') == "minted"){
    ShowConnectButton = 'none';
}
const NavBar = ({accounts, setAccounts}) =>{
    const isConnected = Boolean(accounts[0]);
    async function connectAccount(){
        if (window.ethereum){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }
    return (
        
           
            <Flex justify="space-between" align="center" padding="30px" display={ShowConnectButton}>
                <div className="connect-button">
                {isConnected ? (
                 <Button 
                 float="right"
                 backgroundColor="#ff8300" 
                 borderRadius="5px" 
                 boxShadow="0px 2px 2px 1px #0F0F0F" 
                 color="white" 
                 cursor="ponter" 
                 fontFamily = "inherit" 
                 padding="15px" 
                 margin="0 15px" 
             >
                 Connected
             </Button>
                ):(
                <Button 
                    float="right"
                    backgroundColor="#ff8300" 
                    borderRadius="5px" 
                    boxShadow="0px 2px 2px 1px #0F0F0F" 
                    color="white" 
                    cursor="ponter" 
                    fontFamily = "inherit" 
                    padding="15px" 
                    margin="0 15px" 
                    onClick={connectAccount}
                >
                    Connect
                </Button>  
                )}
                </div>
            </Flex>
            
       
    )
};
export default NavBar;