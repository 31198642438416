import { useState } from "react";
import { ethers, BigNumber } from "ethers";
import {Box, Button, Flex, Input, Text} from '@chakra-ui/react';
import TeamBreakThru from "./TeamBreakThru.json";
import CollectionImage from './assets/images/HandsOn.gif';
const TeamBreakThruContractAddress = "0x41C09F15E510e66cffBc9cc439CFE950870f969C";
var MaxMintAmount = '';
var BalanceInEther = '';
var TokenPriceInEther = '';
var ShowValueBox = 'none';
var MintButtonState = true;
var InfoText = "You must be connected to mint";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
  )
}

if(urlParams.get('param') === "minted"){
    InfoText = "You successfully minted the Open Edition";
    var url = window.location.protocol + '//' + window.location.host + window.location.pathname;  
    sleep(10000).then(()=>{
        window.location.href = url;
     })
}

const MainMint = ({accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const [mintButtonText, setmintButtonText] = useState('Verify');  
    const isConnected = Boolean(accounts[0]);
    if(isConnected) {
        getInfos()  
            .then((val) => {
                var Infos = val;
                var MintEnabled = '';             
                MintEnabled = Infos['MintEnabled'];
                var MintEnabledBool = (MintEnabled === true);
                if(MintEnabledBool){
                    MaxMintAmount = Infos['MintsLeft'];
                    if(MaxMintAmount <= 0){
                        handlemintButtonText("You have reached the max mint amount");    
                    }else{
                        BalanceInEther = Infos['BalanceInEther'];
                        TokenPriceInEther = Infos['TokenPriceInEther'];
                        if(BalanceInEther-(TokenPriceInEther*mintAmount) <= 0){
                            handlemintButtonText("You have not enough ETH");    
                        }else{
                            ShowValueBox = 'flex';
                            MintButtonState = false;
                            handlemintButtonText("Mint Now");
                        }
                    }    
                }else{
                    handlemintButtonText("The mint window is closed");
                }    
            })
            .catch((err) => {
            })
            .finally(() => {
            });       
    }
    async function getInfos(){
        if(window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                TeamBreakThruContractAddress,
                TeamBreakThru.abi,
                signer  
            );
            const Balance = await provider.getBalance(accounts[0]);
            const BalanceInEther = ethers.utils.formatEther(Balance);     
            const Block = await provider.getBlockNumber();
            const TokenName = await contract.name();
            const TokenPrice = await contract.getPrice();
            const TokenPriceInEther = ethers.utils.formatEther(TokenPrice);       
            const MintValue = await contract.getMintValue();
            const MintValueFromat = parseInt(MintValue._hex);
            const MintedValue = await contract.getMintedValue(accounts[0]);
            const MintedValueFromat = parseInt(MintedValue._hex);
            const MintsLeft = MintValueFromat - MintedValueFromat;
            const MintEnabled = await contract.isMintEnabled();           
            var Infos = []; 
            Infos['Block'] = Block;            
            Infos['TokenName'] = TokenName;
            Infos['MintEnabled'] = MintEnabled;  
            Infos['TokenPriceInEther'] = TokenPriceInEther;
            Infos['BalanceInEther'] = BalanceInEther;
            Infos['MintValueFromat'] = MintValueFromat;
            Infos['MintedValueFromat'] = MintedValueFromat;
            Infos['MintsLeft'] = MintsLeft;
            return Infos;
        }  
    }
    async function handleMint() {
        if(window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                TeamBreakThruContractAddress,
                TeamBreakThru.abi,
                signer  
            );         
            const balance = await provider.getBalance(accounts[0]);
            const balanceInEther = ethers.utils.formatEther(balance);    
            const block = await provider.getBlockNumber();
            const tokenName = await contract.name();
            const tokenPrice = await contract.getPrice();
            const tokenPriceInEther = ethers.utils.formatEther(tokenPrice);       
            const MintValue = await contract.getMintValue();
            const MintValueFromat = parseInt(MintValue._hex);
            const MintedValue = await contract.getMintedValue(accounts[0]);
            const MintedValueFromat = parseInt(MintedValue._hex);
            const MintEnabled = await contract.isMintEnabled();                
            if(balanceInEther >= (tokenPriceInEther*mintAmount)){
                try{
                    const response = await contract.mint(BigNumber.from(mintAmount), {
                        value: ethers.utils.parseEther((tokenPriceInEther*mintAmount).toString()),
                });
                            ShowValueBox = 'none';
                            MintButtonState = true;
                            handlemintButtonText("Mint successful");

                            var url = window.location.protocol + '//' + window.location.host + window.location.pathname;    
                            if (url.indexOf('?') > -1){
                            url += '&param=minted'
                            }else{
                            url += '?param=minted'
                            }

                            window.location.href = url;
                } catch (err){
                    ShowValueBox = 'none';
                    MintButtonState = true;
                    handlemintButtonText("Mint failed");
                }   
            }else{
                handlemintButtonText('Not enough ETH');                 
            }
            
            
        }else{
        }
    }
    const handleDecrement = () => {
        if(mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };
    const handleIncrement = () => {
        if(mintAmount >= MaxMintAmount) return;
        setMintAmount(mintAmount + 1);
    };
    const handlemintButtonText = (value) => {
        setmintButtonText(value);
    };


    return(
        <Flex justify="center" align="center" paddingBottom="150px">
            <Box with="520px">
            <img class="CollectionImage" width="150px" src={CollectionImage} alt="Logo" />
                <div>
                    <Text fontFamily="Permanent Marker" fontSize="25px">
                    <span className="TeamBreakThru">TeamBreakThru</span> x MetaHype - Open Edition                   
                    </Text>     
                </div>               
                {isConnected ? (                   
                    <div>
                        <div>Connected Contract: {TeamBreakThruContractAddress}</div>
                        <div>Connected Account: {accounts[0]}</div>
                        <div>Mint Price: 0.00183 Ξth</div>
                        <Flex justify="center" align="center" display={ShowValueBox}>
                        
                        <Button 
                            backgroundColor="#ff8300" 
                            borderRadius="5px" 
                            boxShadow="0px 2px 2px 1px #0F0F0F" 
                            color="white" 
                            cursor="ponter" 
                            fontFamily = "inherit" 
                            padding="15px" 
                            marginTop="10px"                
                            onClick={handleDecrement}
                        >
                            -
                        </Button>
                        <Input 
                            readOnly 
                            fontFamily="inherit"
                            width="100px"
                            height="40px"
                            textAlign="center"
                            paddingLeft="19px"
                            marginTop="10px"
                            type="number" 
                            value={mintAmount}/>
                        <Button 
                            backgroundColor="#ff8300" 
                            borderRadius="5px" 
                            boxShadow="0px 2px 2px 1px #0F0F0F" 
                            color="white" 
                            cursor="ponter" 
                            fontFamily = "inherit" 
                            padding="15px" 
                            marginTop="10px" 
                            onClick={handleIncrement}
                        >
                            +
                        </Button>                       
                        </Flex>
                        <Button    
                            disabled={MintButtonState}                       
                            backgroundColor="#ff8300" 
                            borderRadius="5px" 
                            boxShadow="0px 2px 2px 1px #0F0F0F" 
                            color="white" 
                            cursor="ponter" 
                            fontFamily = "inherit" 
                            padding="15px" 
                            marginTop="10px" 
                            fontFamily="Permanent Marker"
                            onClick={handleMint}
                        >
                            {mintButtonText}
                        </Button>
                    </div>
                ):(
                    <Text
                        marginTop="70px" 
                        fontSize="30px" 
                        fontFamily="Permanent Marker"
                        color="#ff8300"
                    >
                        {InfoText}
                    </Text>
                )}
            </Box>
        </Flex>
    )
}
export default MainMint;